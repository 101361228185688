import { clientsClaim, setCacheNameDetails } from 'workbox-core';
import { precacheAndRoute } from 'workbox-precaching/precacheAndRoute';
import { registerRoute } from 'workbox-routing';
import { CacheFirst } from 'workbox-strategies';
import { ExpirationPlugin } from 'workbox-expiration';

self.skipWaiting();
clientsClaim();

// cache name
setCacheNameDetails({
    prefix: 'pwa-cache',
    precache: 'precache',
    runtime: 'runtime',
});
  
// runtime cache
// 1. stylesheet
registerRoute(
    new RegExp('\.css$'),
    new CacheFirst({
        cacheName: 'pwa-cache-Stylesheets',
        plugins: [
            new ExpirationPlugin({
                maxAgeSeconds: 60 * 60 * 24 * 7, // cache for one week
                maxEntries: 20, // only cache 20 request
                purgeOnQuotaError: true
            })
        ]
    })
);

// 2. images
registerRoute(
    new RegExp('\.(png|svg|jpg|jpeg)$'),
    new CacheFirst({
        cacheName: 'pwa-cache-Images',
        plugins: [
            new ExpirationPlugin({
                maxAgeSeconds: 60 * 60 * 24 * 7,
                maxEntries: 50,
                purgeOnQuotaError: true
            })
        ]
    })
);

// 3. cache news articles result
// registerRoute(
//     new RegExp('https://newsapi.org/v2/top-headlines'),
//     staleWhileRevalidate({
//         cacheName: 'pwa-cache-news-headline',
//         cacheExpiration: {
//             maxAgeSeconds: 60 * 30 //cache the news content for 30mn
//         }
//     })
// );
  
precacheAndRoute(self.__WB_MANIFEST);